import { toKatakana } from './module';

/**
 * フローティングボタンのスクロールに応じた表示切り替え
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupFloatingButtons = ({ floatButtonsSelector }) => {
  const floatButtons = document.querySelector(floatButtonsSelector);
  let startPosition = 0;
  let windowPosition;

  window.addEventListener('scroll', () => {
    windowPosition = window.scrollY;
    if (windowPosition <= startPosition) {
      floatButtons.classList.add('floatButtons--active');
    } else {
      floatButtons.classList.remove('floatButtons--active');
    }
    startPosition = windowPosition;
  });
};

/**
 * 言語メニューの表示制御
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupLanguageMenu = ({
  languageButtonSelector,
  languageMenuSelector,
  closeButtonSelector,
  menuActiveClass,
}) => {
  const languageButton = document.querySelector(languageButtonSelector);
  const languageMenu = document.querySelector(languageMenuSelector);
  const languageCloseButton = languageMenu.querySelector(closeButtonSelector);

  languageButton.addEventListener('click', () => {
    languageMenu.classList.add(menuActiveClass);
    document.body.style.overflow = 'hidden';
  });
  languageCloseButton.addEventListener('click', () => {
    languageMenu.classList.remove(menuActiveClass);
    document.body.style.overflow = 'auto';
  });
};

/**
 * 検索メニューの表示制御
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupSearchMenu = ({ searchButtonSelector, searchMenuSelector, closeButtonSelector, menuActiveClass }) => {
  const searchButton = document.querySelector(searchButtonSelector);
  const searchMenu = document.querySelector(searchMenuSelector);
  const searchCloseButton = searchMenu.querySelector(closeButtonSelector);

  searchButton.addEventListener('click', () => {
    searchMenu.classList.add(menuActiveClass);
    document.body.style.overflow = 'hidden';
  });
  searchCloseButton.addEventListener('click', () => {
    searchMenu.classList.remove(menuActiveClass);
    document.body.style.overflow = 'auto';
  });
};

/**
 * 言語検索機能の設定
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupLanguageSearch = ({ inputSelector, listItemsSelector, messageSelector }) => {
  const languageInput = document.querySelector(inputSelector);
  const languageListItems = document.querySelectorAll(listItemsSelector);
  const noLanguageMessage = document.getElementById(messageSelector);

  languageInput.addEventListener('input', () => {
    const filter = toKatakana(languageInput.value.toUpperCase());
    let found = false;

    languageListItems.forEach((item) => {
      let txtValue = toKatakana(item.textContent.toUpperCase() || item.innerText.toUpperCase());
      if (txtValue.includes(filter)) {
        item.style.display = '';
        found = true;
      } else {
        item.style.display = 'none';
      }
    });

    noLanguageMessage.style.display = found ? 'none' : '';
  });
};
