// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  if (document.querySelector('.newsLayout__dateLink--active')) {
    const element = document.querySelector('.newsLayout__dateLink--active');
    $(`#year${element.dataset.year}`).removeClass('newsLayout__dateLists--none');
  }

  // 年が選択された場合、選択された年をアクティブ状態にしそれ以外の年を非アクティブにする
  // また、選択された年の最終月をアクティブにする
  $('li.newsLayout__dateList--year').on('click', (e) => {
    const targetElement = e.target;
    const closetElement = e.target.closest('.newsLayout__dateLists');
    closetElement.querySelectorAll('a.newsLayout__dateLink').forEach((element) => {
      if (element.dataset.year === e.target.dataset.year) {
        element.classList.add('newsLayout__dateLink--active');
      } else {
        element.classList.remove('newsLayout__dateLink--active');
      }
    });

    document.querySelectorAll('.newsLayout__dateLists--month').forEach((element) => {
      if (element.getAttribute('id') === `year${targetElement.dataset.year}`) {
        element.classList.remove('newsLayout__dateLists--none');
      } else {
        element.classList.add('newsLayout__dateLists--none');
      }
    });
  });

  // 月が選択された場合
  $('li.newsLayout__dateList--month').on('click', (e) => {
    const targetElement = e.target;
    const closetElement = e.target.closest('.newsLayout__dateLists--month');
    closetElement.querySelectorAll('a.newsLayout__dateLink').forEach((element) => {
      if (element.dataset.month === targetElement.dataset.month) {
        element.classList.add('newsLayout__dateLink--active');
      } else {
        element.classList.remove('newsLayout__dateLink--active');
      }
    });
  });
});
