import { SelectBundler } from './SelectBundler';
import { Submit } from './Submit';

// - App
//    - SelectBundler x 2
//      - SelectBtn x n
//      - SelectInput
//    - Submit

export default class Gmap2PointLinkCreateUI {
  constructor({ appSelector, locale = 'ja' }) {
    // 操作に必要なDOMはthis.store.configにマッピングしておく
    this.store = {
      config: {
        locale,
        submitBaseUrl: 'https://www.google.co.jp/maps/dir/',
        selectorName: {
          bundler: '[data-mp-role="gmap-2point-link-create-ui-bundler"]',
          btn: '[data-mp-role="gmap-2point-link-create-ui-btn"]',
          input: '[data-mp-role="gmap-2point-link-create-ui-input"]',
          submit: '[data-mp-role="gmap-2point-link-create-ui-submit"]',
        },
        className: {
          activeSelectBtn: 'is_active',
          activeSelectInput: 'is_active',
        },
        msg: {
          validError: '出発地点と選択地点の両方を選択してください',
        },
      },
      state: {
        start: '',
        end: '',
      },
    };

    this.node = document.querySelector(appSelector);

    new Submit({
      node: this.node.querySelector(this.store.config.selectorName.submit),
      store: this.store,
    });

    this.node.querySelectorAll(this.store.config.selectorName.bundler).forEach((node) => {
      new SelectBundler({
        node,
        store: this.store,
        type: node.dataset.mpBundleType,
      });
    });
  }
}
