import axios from 'axios';
import Rails from '@rails/ujs';

export default class SubmitBtn {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;

    this.elm.addEventListener('click', (e) => {
      e.preventDefault();

      // Google Tag Manager計測用
      this.app.setGTMParams();

      const requestURI = `${this.app.aicAPIBaseURI}?${this.app.getQueries()}`;

      // [note] まずリクエスト開始前にローディングぐるぐるパーツを表示します。
      this.app.loader.show();

      axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
      axios.interceptors.request.use((config) => ({
        ...config,
        headers: { ...config.headers, 'X-CSRF-Token': Rails.csrfToken() },
      }));

      // [note] リクエストしています
      axios
        .get(requestURI)
        .then((res) => {
          // [note] 結果タグ(html)を表示に追加する
          const result = document.getElementById('aic_search_result');
          result.innerHTML = res.data;

          // 結果部分にスクロール
          this.app.aiRoutePlannerSubmitBtnScroller.scroll();

          // [note] ローディングダイアログを停止します。
          this.app.loader.hide();
        })
        .catch((err) => {
          console.log(err.response); // eslint-disable-line no-console
          // [note] ローディングダイアログを停止します。
          this.app.loader.hide();
          return null;
        });
    });
  }
}
