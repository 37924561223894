export default class Loader {
  constructor({ elm, app }) {
    this.app = app;
    this.elm = elm;
    this.store = app.store;
    this.hideClassName = this.elm.dataset.hideClass;
  }

  hide() {
    this.elm.classList.add(this.hideClassName);
  }

  show() {
    this.elm.classList.remove(this.hideClassName);
  }
}
