// ひらがなをカタカナに変換
export const toKatakana = (str) => {
  return str.replace(/[\u3041-\u3096]/g, (match) => {
    var chr = match.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });
};

// カタカナをひらがなに変換
export const toHiragana = (str) => {
  return str.replace(/[\u30a1-\u30f6]/g, (match) => {
    var chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });
};
