// 動画セクションのモーダル

/**
 * 特定のIDを持つモーダルを検索する純粋関数。
 * @param {NodeListOf<Element>} modals - 検索対象のモーダル要素のリスト。
 * @param {string} id - 検索するモーダルのID。
 * @returns {Element|null} - 見つかったモーダル要素、またはnull。
 */
const findModalById = (modals, id) => modals.find((modal) => modal.getAttribute('data-movie-modal-id') === id);

/**
 * モーダルを表示する純粋関数。
 * @param {Element} modal - 表示するモーダル要素。
 */
const showModal = (modal) => {
  modal.classList.add('topVideo__modal--active');
  document.body.style.overflow = 'hidden';
};

/**
 * モーダルを非表示にする純粋関数。
 * @param {Element} modal - 非表示にするモーダル要素。
 */
const hideModal = (modal) => {
  modal.classList.remove('topVideo__modal--active');
  document.body.style.overflow = '';
  modal.querySelector('.topVideo__iframe').src = '';
};

/**
 * Swiperのオートプレイを停止する副作用のある関数。
 * @param {Swiper} swiper - オートプレイを停止するSwiperインスタンス。
 */
const stopSwiperAutoplay = (swiper) => {
  if (swiper && swiper.autoplay) {
    swiper.autoplay.stop();
  }
};

/**
 * iframeにソースURLを設定する関数。
 * @param {Element} modal - ソースURLを設定するモーダル要素。
 */
const setIframeSrc = (modal) => {
  const iframeElement = modal.querySelector('iframe');
  if (!iframeElement) return;

  const src = iframeElement.getAttribute('data-src');
  if (!src) return;

  iframeElement.setAttribute('src', src);
};

/**
 * モーダル内のクリックイベントハンドラを設定する副作用のある関数。
 * @param {Element} modal - イベントハンドラを設定するモーダル要素。
 * @param {function} hideModalFunc - モーダルを非表示にする関数。
 */
const setupClickHandlers = (modal, hideModalFunc) => {
  modal.addEventListener('click', () => hideModalFunc(modal));

  const closeButton = modal.querySelector('.topVideo__modalCloseButton');
  closeButton.addEventListener('click', () => hideModalFunc(modal));

  const modalInner = modal.querySelector('.topVideo__modalInner');
  modalInner.addEventListener('click', (e) => e.stopPropagation());
};

/**
 * モーダル機能を初期化するメインロジック。
 * @param {NodeListOf<Element>} sliders - クリックイベントを設定するスライダー要素のリスト。
 * @param {NodeListOf<Element>} modals - モーダル要素のリスト。
 * @param {Swiper} swiper - Swiperインスタンス。
 */
export const initializeModalFunctionality = (sliders, modals, swiper) => {
  sliders.forEach((slider) => {
    slider.addEventListener('click', () => {
      const movieId = slider.getAttribute('data-movie-slide-id');
      stopSwiperAutoplay(swiper);

      const modal = findModalById(Array.from(modals), movieId);
      if (modal) {
        showModal(modal);
        setupClickHandlers(modal, hideModal);
        setIframeSrc(modal);
      }
    });
  });
};
