// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  function switchAreaAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="photo_area_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[areas_id_in][]"]:checked').length === $('input[name="q[areas_id_in][]"]').length - 1) {
      $('input[name="photo_area_id[all]"]').prop('checked', true);
    }
  }

  function switchGenreAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="photo_genre_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[genres_id_in][]"]:checked').length === $('input[name="q[genres_id_in][]"]').length - 1) {
      $('input[name="photo_genre_id[all]"]').prop('checked', true);
    }
  }

  if ($('#photo_search').length) {
    // エリア全て選択時、カテゴリーの状態も変更する
    $('input[name="photo_area_id[all]"]').click((e) => {
      $('input[name="q[areas_id_in][]"]').prop('checked', e.target.checked);
    });

    // エリア選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[areas_id_in][]"]').click(() => {
      switchAreaAllCheckbox();
    });

    // カテゴリー全て選択時、カテゴリーの状態も変更する
    $('input[name="photo_genre_id[all]"]').click((e) => {
      $('input[name="q[genres_id_in][]"]').prop('checked', e.target.checked);
    });

    // カテゴリー選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[genres_id_in][]"]').click(() => {
      switchGenreAllCheckbox();
    });
  }

  // リストに追加するを押下した場合
  if ($('input[name="download_target"]').length) {
    const targets = document.querySelectorAll(`input[type='checkbox'][name='download_target']`);

    targets.forEach((target) => {
      target.addEventListener('change', (e) => {
        const photoId = e.target.value;
        if (e.target.checked) {
          // 新規登録;
          $.ajax({
            url: `/photo_dl_selections`,
            beforeSend(xhr) {
              xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            type: 'POST',
            dataType: 'json',
            data: { photo_id: photoId },
            timeout: 3000,
          }).fail(function fail() {
            $(`input[name="download_target"][value="${photoId}"]`).prop('checked', false);
          });
        } else {
          $.ajax({
            url: `/photo_dl_selections/${photoId}`,
            beforeSend(xhr) {
              xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            type: 'DELETE',
            dataType: 'json',
            timeout: 3000,
          }).fail(function fail() {
            $(`input[name="download_target"][value="${photoId}"]`).prop('checked', false);
          });
        }
      });
    });
  }

  $('#photo_download').on('click', () => {
    $('input[type="checkbox"][name="download_target"]').prop('checked', false);
  });
});
