// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Swiper from 'swiper/bundle';
import { swiperOptions, initResponsiveSwiper } from './module/SwiperFunctions';
import imageMapResizer from 'image-map-resizer';

$(() => {
  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchAreaAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="feature_area_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[areas_id_in][]"]:checked').length === $('input[name="q[areas_id_in][]"]').length - 1) {
      $('input[name="feature_area_id[all]"]').prop('checked', true);
    }
  }

  function switchTagAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="feature_tag_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[tags_id_in][]"]:checked').length === $('input[name="q[tags_id_in][]"]').length - 1) {
      $('input[name="feature_tag_id[all]"]').prop('checked', true);
    }
  }

  function switchGenreAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="feature_genre_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[genres_id_in][]"]:checked').length === $('input[name="q[genres_id_in][]"]').length - 1) {
      $('input[name="feature_genre_id[all]"]').prop('checked', true);
    }
  }

  if ($('#feature_search').length) {
    // エリア全て選択時、エリアの状態も変更する
    $('input[name="feature_area_id[all]"]').click((e) => {
      $('input[name="q[areas_id_in][]"]').prop('checked', e.target.checked);
    });

    // エリア選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[areas_id_in][]"]').click(() => {
      switchAreaAllCheckbox();
    });

    // タグ全て選択時、タグの状態も変更する
    $('input[name="feature_tag_id[all]"]').click((e) => {
      $('input[name="q[tags_id_in][]"]').prop('checked', e.target.checked);
    });

    // タグ選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[tags_id_in][]"]').click(() => {
      switchTagAllCheckbox();
    });

    // カテゴリー全て選択時、カテゴリーの状態も変更する
    $('input[name="feature_genre_id[all]"]').click((e) => {
      $('input[name="q[genres_id_in][]"]').prop('checked', e.target.checked);
    });

    // カテゴリー選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[genres_id_in][]"]').click(() => {
      switchGenreAllCheckbox();
    });
  }

  //////features
  ////search formの表示・非表示・popupの位置調整
  // 純粋関数: ポップアップの位置を計算
  const calculatePopupPosition = (buttonElement, margin = 10) => {
    const { height, top } = buttonElement.getBoundingClientRect();
    return height + top + margin + window.scrollY;
  };

  // DOM操作: ポップアップの位置を調整
  const setPopupPosition = (popup, position) => {
    popup.style.top = `${position}px`;
  };

  // 純粋関数: アクティブなコンテンツのロールを取得
  const getActiveContentRole = (button) => `search-form-popup-${button.dataset.role.split('-')[4]}`;

  // DOM操作: モーダルとコンテンツの表示切り替え
  const toggleModalAndContents = (popup, contents, role) => {
    popup.classList.add('searchForm__categoryPopUp--active');
    contents.forEach((content) => {
      content.classList.toggle('searchForm__categoryContents--active', content.dataset.role === role);
    });
  };

  // DOM操作: ボタンのアクティブ状態の切り替え
  const toggleActiveButton = (buttons, activeButton) => {
    buttons.forEach((btn) => btn.classList.toggle('u-searchFormCategoryButton--active', btn === activeButton));
  };

  // イベントハンドラの設定と削除
  const setupButtonEventListeners = (buttons, popup, contents) => {
    let resizeHandler = null;

    buttons.forEach((button) => {
      button.addEventListener('click', () => {
        const role = getActiveContentRole(button);
        toggleModalAndContents(popup, contents, role);
        setPopupPosition(popup, calculatePopupPosition(button));
        toggleActiveButton(buttons, button);

        if (resizeHandler) {
          window.removeEventListener('resize', resizeHandler);
        }
        resizeHandler = () => setPopupPosition(popup, calculatePopupPosition(button));
        window.addEventListener('resize', resizeHandler);
      });
    });

    return () => {
      if (resizeHandler) {
        window.removeEventListener('resize', resizeHandler);
      }
    };
  };

  // イベントハンドラの削除
  const removeResizeHandler = setupButtonEventListeners(
    document.querySelectorAll('.searchForm__categoryButton'),
    document.querySelector('.searchForm__categoryPopUp'),
    document.querySelectorAll('[data-role="search-form"] .searchForm__categoryContents')
  );

  // クローズボタンイベント
  const closeButton = document.querySelector('.searchForm__categoryPopUpCloseButton');
  if (closeButton) {
    closeButton.addEventListener('click', () => {
      document.querySelector('.searchForm__categoryPopUp').classList.remove('searchForm__categoryPopUp--active');
      document
        .querySelectorAll('[data-role="search-form"] .searchForm__categoryContents')
        .forEach((content) => content.classList.remove('searchForm__categoryContents--active'));
      document
        .querySelectorAll('.searchForm__categoryButton')
        .forEach((button) => button.classList.remove('u-searchFormCategoryButton--active'));
      removeResizeHandler();
    });
  }

  //// search_formのエリア
  const toggleCheckedState = (checkbox, label, areaId) => {
    const isChecked = checkbox.checked;
    const correspondingImage = document.getElementById(areaId);

    // チェックボックス、ラベル、画像の状態をトグル
    if (label) label.classList.toggle('checked', isChecked);
    if (correspondingImage) correspondingImage.classList.toggle('checked', isChecked);
  };

  // ページ読み込み時にチェックボックスの状態を設定
  document.querySelectorAll('.searchForm__areaCheckboxLabel .searchForm__areaMapCheckbox').forEach((checkbox) => {
    const label = checkbox.closest('.searchForm__areaCheckboxLabel');
    const areaId = label.getAttribute('data-area-id');
    toggleCheckedState(checkbox, label, areaId);
  });

  document.querySelectorAll('.searchForm__areaCheckboxLabel').forEach((label) => {
    label.addEventListener('click', () => {
      const checkbox = label.querySelector('.searchForm__areaMapCheckbox');
      const areaId = label.getAttribute('data-area-id');
      if (checkbox && areaId) {
        checkbox.checked = !checkbox.checked; // チェックボックスの状態をトグル
        toggleCheckedState(checkbox, label, areaId);
      }
    });
  });

  document.querySelectorAll('[data-role="area-map-image"]').forEach((area) => {
    area.addEventListener('click', (e) => {
      e.preventDefault();
      const areaId = e.target.getAttribute('data-area-id');
      const correspondingCheckbox = document.querySelector(
        `.searchForm__areaCheckboxLabel--${areaId} .searchForm__areaMapCheckbox`
      );
      const correspondingLabel = document.querySelector(`.searchForm__areaCheckboxLabel--${areaId}`);
      if (correspondingCheckbox && correspondingLabel) {
        correspondingCheckbox.checked = !correspondingCheckbox.checked;
        toggleCheckedState(correspondingCheckbox, correspondingLabel, areaId);
      }
    });
  });

  imageMapResizer();

  // /features/[:id]
  const initializeAllThemeSwipers = (swiperDataRole, paginationDataRole) => {
    const swiperElements = document.querySelectorAll(`[data-role="${swiperDataRole}"]`);
    const paginationElements = document.querySelectorAll(`[data-role="${paginationDataRole}"]`);

    swiperElements.forEach((swiperElement, index) => {
      const paginationElement = paginationElements[index];
      if (!swiperElement || !paginationElement) return;

      new Swiper(
        swiperElement,
        swiperOptions({
          slidesPerView: 1,
          pagination: {
            el: paginationElement,
            clickable: true,
          },
        })
      );
    });
  };

  // すべてのSwipersを初期化する
  initializeAllThemeSwipers('single-post-swiper', 'single-post-swiper-pagination');
  initializeAllThemeSwipers('single-theme-swiper', 'single-theme-swiper-pagination');

  // 関連スポットのsp用スライダー
  const relatedArticleSwiperElement = document.querySelectorAll('[data-role="related-article-swiper"]');

  if (relatedArticleSwiperElement.length > 0) {
    relatedArticleSwiperElement.forEach((swiperElement) => {
      const options = swiperOptions({
        slidesPerView: 1,
        navigation: {
          nextEl: '.u-swiperButton--next',
          prevEl: '.u-swiperButton--prev',
        },
        breakpoints: {
          424: {
            slidesPerView: 2,
            spaceBetween: 32,
          },
        },
      });

      initResponsiveSwiper(swiperElement, options);
    });
  }
});
