// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  function switchGenreAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="material_genre_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[genres_id_in][]"]:checked').length === $('input[name="q[genres_id_in][]"]').length - 1) {
      $('input[name="material_genre_id[all]"]').prop('checked', true);
    }
  }

  if ($('#material_search').length) {
    // カテゴリー全て選択時、カテゴリーの状態も変更する
    $('input[name="material_genre_id[all]"]').click((e) => {
      $('input[name="q[genres_id_in][]"]').prop('checked', e.target.checked);
    });

    // カテゴリー選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[genres_id_in][]"]').click(() => {
      switchGenreAllCheckbox();
    });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  // サムネイル画像を取得
  const previewThumbnails = document.querySelectorAll('[data-role="preview-thumbnail"]');

  // 各サムネイル画像にクリックイベントを設定
  previewThumbnails.forEach((thumbnail) => {
    thumbnail.addEventListener('click', () => {
      // ファイルの種類とURLを取得
      const fileType = thumbnail.getAttribute('data-file-type');
      const fileUrl = thumbnail.getAttribute('data-file-url');
      const modal = document.getElementById('preview-modal');
      const previewContent = document.getElementById('preview-content');

      // テンプレートを選択してコンテンツを生成
      let template;
      if (fileType === 'pdf') {
        template = document.getElementById('pdf-template').content.cloneNode(true);
        template.querySelector('iframe').src = fileUrl;
      } else if (fileType === 'image') {
        template = document.getElementById('image-template').content.cloneNode(true);
        template.querySelector('img').src = fileUrl;
      } else if (fileType === 'audio') {
        template = document.getElementById('audio-template').content.cloneNode(true);
        template.querySelector('source').src = fileUrl;
      } else if (fileType === 'video') {
        template = document.getElementById('video-template').content.cloneNode(true);
        template.querySelector('source').src = fileUrl;
      } else {
        template = document.createElement('div');
        template.textContent = 'Unsupported file type';
      }

      // プレビューコンテンツを設定してモーダルを表示
      previewContent.innerHTML = '';
      previewContent.appendChild(template);
      modal.style.display = "block";
    });
  });

  // プレビューを閉じる関数
  const closePreview = () => {
    const modal = document.getElementById('preview-modal');
    const previewContent = document.getElementById('preview-content');
    previewContent.innerHTML = '';
    modal.style.display = "none";
  }

  // モーダル外をクリックしたときに閉じる
  window.onclick = (event) => {
    const modal = document.getElementById('preview-modal');
    if (event.target == modal) {
      closePreview();
    }
  };

  // モーダルの閉じるボタンをクリックしたときに閉じる
  const closeModal = document.getElementById('close-modal');
  if (closeModal) {
    closeModal.addEventListener('click', closePreview);
  }
});
