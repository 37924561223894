export default class PrefSlider {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;
    this.target = this.elm.dataset.target;

    // slider操作時のcard選択を解除する記述
    // this.elm.addEventListener('change', () => {
    //   this.app.inactivePrefPresetCards();
    // });
  }

  update(params) {
    Object.keys(params).forEach((key) => {
      if (this.target === key) this.elm.value = params[key];
    });
  }
}
