// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Swiper from 'swiper/bundle';
import { swiperOptions, toggleSwiperAutoplay } from './module/SwiperFunctions';
import { initializeModalFunctionality } from './module/VideoModalFunctions';

$(() => {
  if ($('#top_ai_recommend_spot').length) {
    $.ajax({
      url: '/top_ai_recommend',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  // サムネイル
  const sliderThumbnail = new Swiper('.mainView__swiperThumbs', {
    slidesPerView: 3.5, // サムネイルの枚数
    spaceBetween: 5,
  });

  const mainViewSwiper = new Swiper(
    '.mainView__swiper',
    swiperOptions({
      slidesPerView: 1,
      navigation: {
        nextEl: '.mainView__swiperNextButton',
        prevEl: '.mainView__swiperPrevButton',
      },
      thumbs: {
        swiper: sliderThumbnail,
      },
    })
  );

  const mainViewSwiperStopButton = document.querySelector('.mainView__swiperStopButton');
  if (mainViewSwiperStopButton) {
    toggleSwiperAutoplay(mainViewSwiperStopButton, mainViewSwiper);
  }

  // 特集記事セクション
  const topFeatureArticlesSwiper = new Swiper(
    '.topFeature__articlesSwiper',
    swiperOptions({
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 32,
      navigation: {
        nextEl: '.topFeature__swiperNextButton',
        prevEl: '.topFeature__swiperPrevButton',
      },
      breakpoints: {
        600: {
          slidesPerView: 1.5,
        },
        1280: {
          slidesPerView: 2.5,
        },
        1680: {
          slidesPerView: 2.75,
        },
        1920: {
          slidesPerView: 3,
        },
      },
    })
  );

  const topFeatureSwiperStopButton = document.querySelector('.topFeature__swiperStopButton');
  toggleSwiperAutoplay(topFeatureSwiperStopButton, topFeatureArticlesSwiper);

  // あなたの旅の好みはどっちセクション
  const topLandmarkContentSwiperFirst = new Swiper(
    '.topLandmarkContent.topLandmarkContent--first .topLandmarkContent__swiper',
    swiperOptions({
      slidesPerView: 2,
      allowTouchMove: false,
      spaceBetween: 16,
      navigation: {
        nextEl: '.topLandmarkContent__swiperNextButton',
        prevEl: '.topLandmarkContent__swiperPrevButton',
      },
      breakpoints: {
        424: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 3.5,
          spaceBetween: 24,
        },
        1440: {
          slidesPerView: 4.5,
        },
        1660: {
          slidesPerView: 5.5,
        },
        1920: {
          slidesPerView: 6.5,
        },
      },
    })
  );

  const topLandmarkContentSwiperStopButtonFirst = document.querySelector(
    '.topLandmarkContent--first .topLandmarkContent__swiperStopButton'
  );
  toggleSwiperAutoplay(topLandmarkContentSwiperStopButtonFirst, topLandmarkContentSwiperFirst);

  const topLandmarkContentSwiperSecond = new Swiper(
    '.topLandmarkContent.topLandmarkContent--second .topLandmarkContent__swiper',
    swiperOptions({
      slidesPerView: 2,
      allowTouchMove: false,
      spaceBetween: 16,
      navigation: {
        nextEl: '.topLandmarkContent__swiperNextButton',
        prevEl: '.topLandmarkContent__swiperPrevButton',
      },
      breakpoints: {
        424: {
          slidesPerView: 3,
        },
        768: {
          spaceBetween: 24,
          slidesPerView: 3.5,
        },
        1440: {
          slidesPerView: 4.5,
        },
        1660: {
          slidesPerView: 5.5,
        },
        1920: {
          slidesPerView: 6.5,
        },
      },
    })
  );

  const topLandmarkContentSwiperStopButtonSecond = document.querySelector(
    '.topLandmarkContent--second .topLandmarkContent__swiperStopButton'
  );
  toggleSwiperAutoplay(topLandmarkContentSwiperStopButtonSecond, topLandmarkContentSwiperSecond);

  // ランキングセクション
  const tabButtons = document.querySelectorAll('.topRanking__tabButton');
  const contentLists = document.querySelectorAll('.topRanking__content');

  if (tabButtons && contentLists) {
    const setActiveTab = (tabId) => {
      tabButtons.forEach((button) => {
        if (button.dataset.role === tabId) {
          button.classList.add('u-tabButton--active');
        } else {
          button.classList.remove('u-tabButton--active');
        }
      });

      contentLists.forEach((list) => {
        if (list.dataset.role === tabId) {
          list.classList.add('topRanking__content--active');
        } else {
          list.classList.remove('topRanking__content--active');
        }
      });
    };

    tabButtons.forEach((button) => {
      button.addEventListener('click', () => setActiveTab(button.dataset.role));
    });

    // 初期表示時に最初のタブをアクティブに
    if (tabButtons.length > 0) {
      setActiveTab(tabButtons[0].dataset.role);
    }
  }

  // videoセクションのswiper
  const topVideoSwiperElement = document.querySelector('.topVideo__swiper');
  let topVideoSwiper;
  if (topVideoSwiperElement) {
    topVideoSwiper = new Swiper(
      topVideoSwiperElement,
      swiperOptions({
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          nextEl: '.topVideo__swiperNextButton',
          prevEl: '.topVideo__swiperPrevButton',
        },
        freeMode: true,
        freeModeMomentum: true,
        freeModeMomentumRatio: 2,
        freeModeMomentumBounce: true,
        freeModeMomentumBounceRatio: 0.3,
        freeModeSticky: true,
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          1160: {
            slidesPerView: 3,
            spaceBetween: 32,
          },
        },
      })
    );

    const topVideoSwiperStopButton = document.querySelector('.topVideo__swiperStopButton');
    if (topVideoSwiperStopButton) {
      toggleSwiperAutoplay(topVideoSwiperStopButton, topVideoSwiper);
    }
  }

  // 動画セクションのモーダル
  const movieSliders = document.querySelectorAll('.topVideo__thumbnailButton');
  const movieModals = document.querySelectorAll('.topVideo__modal');
  initializeModalFunctionality(movieSliders, movieModals, topVideoSwiper);
});
