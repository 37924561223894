/**
 * Cookie同意ポップアップの表示と同意処理を設定する関数
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupCookieConsent = ({ consentPopupId, consentButtonId, consentKey, displayClass }) => {
  const consentPopup = document.getElementById(consentPopupId);
  const acceptButton = document.getElementById(consentButtonId);
  const consent = localStorage.getItem(consentKey);

  if (consent !== 'true') {
    // ローカルストレージに同意の記録がない場合、ポップアップを表示
    consentPopup.classList.add(displayClass);
  }

  acceptButton.addEventListener('click', () => {
    // 同意するをクリックした場合、ローカルストレージに同意を記録し、ポップアップを非表示にする
    localStorage.setItem(consentKey, 'true');
    consentPopup.classList.remove(displayClass);
  });
};
