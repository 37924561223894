// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

if (document.querySelector('#tabCmn') !== null) {
  $(() => {
    $('.tabCmnMenu__item').on('click', (event) => {
      const tabWrap = $(event.currentTarget).parents('#tabCmn');
      const tabBtn = tabWrap.find('.tabCmnMenu__item');
      const tabContents = tabWrap.find('.tabCmnContents');
      tabBtn.removeClass('is-current');
      $(event.currentTarget).addClass('is-current');
      const elmIndex = tabBtn.index($(event.currentTarget));
      tabContents.removeClass('is-current');
      tabContents.eq(elmIndex).addClass('is-current');
    });
  });
}
