/**
 * 特定の要素へスムーススクロールする関数
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupSmoothScroll = ({ scrollToSelector, headerSelector }) => {
  const scrollToElements = document.querySelectorAll(scrollToSelector);
  scrollToElements.forEach(element => {
    element.addEventListener('click', event => {
      event.preventDefault();
      const targetId = element.getAttribute('href').substring(1);
      const targetElement = document.getElementById(targetId);
      const headerHeight = document.querySelector(headerSelector).offsetHeight;

      window.scrollTo({
        top: targetElement.offsetTop - headerHeight,
        behavior: 'smooth',
      });
    });
  });
};

/**
 * トップへ戻るボタンの表示をトグルする関数
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupScrollTopButton = ({ scrollTopButtonSelector, activeClass }) => {
  const scrollTopButton = document.querySelector(scrollTopButtonSelector);
  if (scrollTopButton) {
    const toggleButtonVisibility = () => {
      if (window.scrollY > 70) {
        scrollTopButton.classList.add(activeClass);
      } else {
        scrollTopButton.classList.remove(activeClass);
      }
    };

    toggleButtonVisibility();
    window.addEventListener('scroll', toggleButtonVisibility);
    scrollTopButton.addEventListener('click', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }
};
