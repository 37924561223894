import { setupCookieConsent } from './module/CookieConsentFunctions';

// 設定オブジェクト
const config = {
  consentPopupId: 'cookie-consent-popup',
  consentButtonId: 'cookie-consent-button',
  consentKey: 'cookieConsent',
  displayClass: 'u-displayBlock'
};

// DOMの読み込みが完了したら設定する
document.addEventListener('DOMContentLoaded', () => {
  setupCookieConsent(config);
});
