import Gmap2PointLinkCreateUI from './components/Gmap2PointLinkCreateUI/App';
import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  const outlineMapImages = document.querySelectorAll('.access__outlineMapImage img');
  const zoomBackground = document.querySelector('.access__outlineMapZoom');
  const zoomImg = document.querySelector('.access__outlineMapZoom img');

  // 地図の画像をクリックしたら拡大
  if (outlineMapImages && zoomBackground && zoomImg) {
    const upScale = (e) => {
      zoomBackground.style.display = 'flex';
      zoomImg.setAttribute('src', e.target.getAttribute('src'));
      zoomImg.classList.add('access__outlineMapZoom--scale');
    };

    outlineMapImages.forEach(function (image) {
      image.addEventListener('click', upScale);
    });

    const downScale = () => {
      zoomBackground.style.display = 'none';
      zoomImg.classList.remove('access__outlineMapZoom--scale');
    };
    zoomBackground.addEventListener('click', downScale);
  }
});

// ---------------------------------
// routePage
// ---------------------------------
if (document.querySelector('.js-access-gmap-2point-link-create-ui')) {
  new Gmap2PointLinkCreateUI({
    appSelector: '.js-access-gmap-2point-link-create-ui',
    locale: document.querySelector('.js-access-gmap-2point-link-create-ui').dataset.locale,
  });
}

$(() => {
  $(() => {
    $('.js-accordion').on('click', function () {
      $(this).children('.js-accordion-arrow').toggleClass('is-active');
      $(this).toggleClass('is-active');
      $(this).next('.js-accordion-panel').slideToggle(300, 'linear');
      $(this).next('.js-accordion-panel').toggleClass('is-active');
    });
  });
});
