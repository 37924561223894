// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  if ($('#side_content_ai_recommend_spot').length) {
    $.ajax({
      url: '/side_content_ai_recommend',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }

  if ($('#side_content_ai_item_recommend_spot').length) {
    $.ajax({
      url: '/side_content_ai_item_recommend_spot',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }

  if ($('#side_content_ai_item_recommend_event').length) {
    $.ajax({
      url: '/side_content_ai_item_recommend_event',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }
});
