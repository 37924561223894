import {
  setupFloatingButtons,
  setupLanguageMenu,
  setupSearchMenu,
  setupLanguageSearch,
} from './module/FloatButtonFunctions';

// 設定オブジェクト
const config = {
  floatButtonsSelector: '.floatButtons',
  languageButtonSelector: '[data-role="float-Button-language"]',
  languageMenuSelector: '[data-role="float-menu-language"]',
  closeButtonSelector: '.floatMenu__closeButton',
  searchButtonSelector: '[data-role="float-Button-search"]',
  searchMenuSelector: '[data-role="float-menu-search"]',
  inputSelector: '.floatMenu__languageInput',
  listItemsSelector: '.floatMenu__contentsList',
  messageSelector: 'no-language-message',
  menuActiveClass: 'floatMenu--active',
};

// DOMの読み込みが完了したら設定する
document.addEventListener('DOMContentLoaded', () => {
  // フローティングボタンの設定
  const floatingButtonsConfig = {
    floatButtonsSelector: config.floatButtonsSelector,
  };
  setupFloatingButtons(floatingButtonsConfig);

  // 言語メニューの設定
  const languageMenuConfig = {
    languageButtonSelector: config.languageButtonSelector,
    languageMenuSelector: config.languageMenuSelector,
    closeButtonSelector: config.closeButtonSelector,
    menuActiveClass: config.menuActiveClass,
  };
  setupLanguageMenu(languageMenuConfig);

  // 検索メニューの設定
  const searchMenuConfig = {
    searchButtonSelector: config.searchButtonSelector,
    searchMenuSelector: config.searchMenuSelector,
    closeButtonSelector: config.closeButtonSelector,
    menuActiveClass: config.menuActiveClass,
  };
  setupSearchMenu(searchMenuConfig);

  // 言語検索機能の設定
  const languageSearchConfig = {
    inputSelector: config.inputSelector,
    listItemsSelector: config.listItemsSelector,
    messageSelector: config.messageSelector,
  };
  setupLanguageSearch(languageSearchConfig);
});
