export default class StyleCard {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;
    this.elm.addEventListener('click', () => {
      this.app.styleCards.forEach((card) => {
        card.elm.classList.remove(this.app.activeClassName);
      });
      this.active();
      return false;
    });
  }

  active() {
    this.elm.classList.add(this.app.activeClassName);
  }

  inactive() {
    this.elm.classList.remove(this.app.activeClassName);
  }
}
