import { setupQuestionToggle } from './module/QuestionsFunctions';

// 設定オブジェクト
const config = {
  questionSelector: '.questions__question',
  answerActiveClass: 'questions__answer--active'
};

// DOMの読み込みが完了したら設定する
document.addEventListener('DOMContentLoaded', () => {
  setupQuestionToggle(config);
});
