import Swiper from 'swiper/bundle';
import { swiperOptions } from '../module/SwiperFunctions';

if (document.querySelector("[data-role='activityInfoSwiper']") !== null) {
  new Swiper("[data-role='activityInfoSwiper']", swiperOptions({
    slidesPerView: 1,
    spaceBetween: 16,
    centeredSlides: true,
    navigation: {
      nextEl: '.u-swiperButton--next',
      prevEl: '.u-swiperButton--prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 1.7,
      },
    },
    pagination: {
      el: '.activityInfo__swiperPagination',
      clickable: true,
    },
  }));
}
