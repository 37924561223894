import { observeHeaderPosition, setupMenuToggle } from './module/HeaderFunctions';

// 設定オブジェクト
const config = {
  headerSelector: '.header',
  sentinelSelector: '[data-role="header-sentinel"]',
  openButtonSelector: '.header__openButton',
  fixedClass: 'header--fixed',
  activeClass: 'header--active'
};

// DOMの読み込みが完了したらリスナーを設定
document.addEventListener('DOMContentLoaded', () => {
  // observeHeaderPositionに必要な設定のみを抽出
  const observeConfig = {
    headerSelector: config.headerSelector,
    sentinelSelector: config.sentinelSelector,
    fixedClass: config.fixedClass
  };
  observeHeaderPosition(observeConfig);

  // setupMenuToggleに必要な設定のみを抽出
  const menuToggleConfig = {
    headerSelector: config.headerSelector, // ここは同じセレクタを使用
    openButtonSelector: config.openButtonSelector,
    activeClass: config.activeClass
  };
  setupMenuToggle(menuToggleConfig);
});
