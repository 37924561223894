import Swiper from 'swiper/bundle';
import { swiperOptions } from '../module/SwiperFunctions';

const nearbySpotsSwiperElm = document.querySelectorAll('[data-role="nearbySpotsSwiper"]');
if (nearbySpotsSwiperElm.length) {
  nearbySpotsSwiperElm.forEach((elm) => {
    new Swiper(elm, swiperOptions({
      slidesPerView: 2,
      spaceBetween: 24,
      navigation: {
        nextEl: elm.querySelector('.u-swiperButton--next'),
        prevEl: elm.querySelector('.u-swiperButton--prev'),
      },
      breakpoints: {
        768: {
          slidesPerView: 2.2,
          spaceBetween: 32,
        },
      },
    }));
  });
}
