/**
 * クラスの切り替えを行う関数
 * @param {Element} element - DOM要素
 * @param {string} className - 切り替えるクラス名
 * @param {boolean} condition - クラスを追加するかどうかの条件
 */
export const toggleClass = (element, className, condition) => {
  if (condition) {
    element.classList.add(className);
  } else {
    element.classList.remove(className);
  }
};

/**
 * ヘッダーの位置を観察し、特定のクラスをトグルする関数
 * @param {Object} config - 構成設定オブジェクト
 */
export const observeHeaderPosition = ({ headerSelector, sentinelSelector, fixedClass }) => {
  const header = document.querySelector(headerSelector);
  const sentinel = document.querySelector(sentinelSelector);

  // sentinel要素が存在しない場合は、処理を行わない
  if (!sentinel) {
    return;
  }

  const setInitialClass = () => {
    const sentinelPosition = sentinel.getBoundingClientRect().top + window.scrollY;
    toggleClass(header, fixedClass, window.scrollY > sentinelPosition);
  };

  setInitialClass();

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  };

  new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      toggleClass(header, fixedClass, !entry.isIntersecting);
    });
  }, observerOptions).observe(sentinel);
};

/**
 * ハンバーガーメニューのトグル機能を設定する関数
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupMenuToggle = ({ headerSelector, openButtonSelector, activeClass }) => {
  const header = document.querySelector(headerSelector);
  const headerOpenButton = document.querySelector(openButtonSelector);

  if (header && headerOpenButton) {
    headerOpenButton.addEventListener('click', () => {
      header.classList.toggle(activeClass);
      document.body.style.overflow = header.classList.contains(activeClass) ? 'hidden' : '';
    });
  }
};
