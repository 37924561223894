/**
 * 質問セクションのアコーディオン機能を設定する関数
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupQuestionToggle = ({ questionSelector, answerActiveClass }) => {
  const questions = document.querySelectorAll(questionSelector);

  questions.forEach(question => {
    question.addEventListener('click', () => {
      const answer = question.nextElementSibling;

      // クラスがすでに存在するか確認して、max-heightを設定する
      if (answer.classList.contains(answerActiveClass)) {
        // クラスを削除し、高さを0にする
        answer.style.maxHeight = null;
        answer.classList.remove(answerActiveClass);
      } else {
        // クラスを追加し、実際の高さを設定する
        answer.style.maxHeight = answer.scrollHeight + "px";
        answer.classList.add(answerActiveClass);
      }
    });
  });
};
