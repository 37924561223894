import Swiper from 'swiper/bundle';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.coursesShowRecommend__swiper')) {
    const souseShowRecommendSwiper = new Swiper('.coursesShowRecommend__swiper', {
      slidesPerView: 1,
      loop: true,
      autoplay: false,
      speed: 3000,
      disableOnInteraction: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
});
