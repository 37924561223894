import { initResponsiveSwiper, swiperOptions } from '../module/SwiperFunctions';

const sideRecommendSwiperOptions = swiperOptions({
  slidesPerView: 2,
  spaceBetween: 16,
  navigation: {
    nextEl: '.u-swiperButton--next',
    prevEl: '.u-swiperButton--prev',
  },
});

const sideRecommendSwiperElements = document.querySelectorAll('[data-role="sideRecommendSwiper"]');
if (sideRecommendSwiperElements) {
  sideRecommendSwiperElements.forEach((element) => {
    initResponsiveSwiper(element, sideRecommendSwiperOptions)
  })
}
