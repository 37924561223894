import Swiper from 'swiper/bundle';

/**
 * Swiperのオプションを生成する関数。
 * カスタムオプションでデフォルトオプションを上書きする。
 *
 * @param {Object} customOptions - Swiperに適用するカスタムオプション。
 * @param {Object|null} newDefaultOptions - 新しいデフォルトオプション。指定されない場合は、内部で定義されたデフォルトオプションが使用される。
 * @returns {Object} - 結合されたSwiperオプション。
 */
export const swiperOptions = (customOptions = {}, newDefaultOptions = null) => {
  const defaultOptions = newDefaultOptions || {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    speed: 3000,
  };

  return {
    ...defaultOptions,
    ...customOptions,
    autoplay: {
      ...defaultOptions.autoplay,
      ...customOptions.autoplay
    }
  };
};

/**
 * 特定の最大幅以下でのみSwiperインスタンスを初期化し、
 * ウィンドウのサイズが変更されたときに適切に応答する関数。
 *
 * @param {string | Element} container - Swiperインスタンスを初期化するコンテナのセレクタまたは要素。
 * @param {Object} options - Swiperインスタンスに適用するオプション。
 * @param {number} maxWidth - Swiperを初期化する最大の画面幅（ピクセル単位）。デフォルトは 768px。
 * @returns {Object} - Swiperインスタンスを破棄するための `destroy` メソッドを含むオブジェクト。
 */
export const initResponsiveSwiper = (container, options, maxWidth = 768) => {
  let swiper = null;

  const initSwiper = () => {
    if (window.innerWidth <= maxWidth) {
      return new Swiper(container, options);
    }
    return null;
  };

  const onResize = () => {
    if (swiper && window.innerWidth > maxWidth) {
      swiper.destroy(true, true);
      swiper = null;
    } else if (!swiper && window.innerWidth <= maxWidth) {
      swiper = initSwiper();
    }
  };

  swiper = initSwiper();
  window.addEventListener('resize', onResize);

  return {
    destroy: () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
      window.removeEventListener('resize', onResize);
    },
  };
};

/**
 * ボタンのスタイルをSwiperのautoplay状態に基づいて更新する関数
 *
 * @param {boolean} isAutoplayRunning - Swiperのautoplayが実行中かどうかを示すブール値。
 * @param {HTMLElement} buttonElement - 停止ボタンの要素。
 */
const updateButtonState = (isAutoplayRunning, buttonElement) => {
  if (isAutoplayRunning) {
    buttonElement.classList.remove('u-snsButton--stop');
    buttonElement.classList.add('u-snsButton--start');
  } else {
    buttonElement.classList.remove('u-snsButton--start');
    buttonElement.classList.add('u-snsButton--stop');
  }
};

/**
 * Swiperのautoplayをトグルするイベントリスナーを設定する関数。
 * Swiperのautoplay状態に基づいて、指定されたボタンのスタイルを切り替える。
 *
 * @param {HTMLElement} buttonElement - クリックイベントを設定するボタン要素。
 * @param {Swiper} swiperInstance - 制御するSwiperインスタンス。
 */
export const toggleSwiperAutoplay = (buttonElement, swiperInstance) => {
  if (buttonElement) {
    // ボタンの初期状態を設定
    updateButtonState(swiperInstance.autoplay.running, buttonElement);

    // ボタンクリックイベントの設定
    buttonElement.addEventListener('click', function () {
      if (swiperInstance.autoplay.running) {
        swiperInstance.autoplay.stop();
      } else {
        swiperInstance.autoplay.start();
      }
    });

    // Swiperのautoplay状態変更イベントの設定
    swiperInstance.on('autoplayStart', () => updateButtonState(true, buttonElement));
    swiperInstance.on('autoplayStop', () => updateButtonState(false, buttonElement));
  }
};
